import { CSSProperties } from "react";
import AvatarHeads from "../global/avatars";
import styles from './avatar.module.css';

type Props = {
    className?: string;
    onClick?: React.MouseEventHandler<HTMLImageElement>;
    src: string;
    style?: CSSProperties;
    title?: string;
}

export default function PlayerAvatar({
    className,
    onClick,
    src,
    style,
    title,
}: Props): JSX.Element {
    if (!src) {
        src = AvatarHeads[582];
    }

    let classes = className ?? '';
    if (onClick) {
        classes += ' ' + styles.clickable;
    }

    return (
        <picture style={{ display: 'inline-flex' }}>
            <img
                className={classes}
                title={title}
                onClick={onClick}
                src={`https://static.spqr.app/avatars/${src}`}
                style={style}
                alt={title}
            />
        </picture>
    );
}
